import { Asset } from "@/types/report.type";
import React from "react";
import { Avatar, AvatarImage } from "../ui/avatar";

interface AvatarWithLabelProps {
  asset?: Asset;
  network?: Asset;
}

const NetworkAsset: React.FC<AvatarWithLabelProps> = ({ asset, network }) => {
  let isSame = false;
  if (asset && network) {
    isSame = asset.label === network.label && asset.name === network.name;
  }

  return (
    <div className="relative group w-6 h-8">
      {network && asset && (
        <>
          {isSame ? (
            <div className="absolute top-0 left-0">
              <Avatar className="w-8 h-8 rounded-full bg-gray-300 p-1">
                <AvatarImage src={asset.icon} alt={asset.label} />
              </Avatar>
            </div>
          ) : (
            <>
              <div className="absolute top-0 left-0">
                <Avatar className="w-8 h-8 rounded-full bg-gray-300">
                  <AvatarImage src={network.icon} alt={network.label} />
                </Avatar>
              </div>

              <div className="absolute top-2 left-4">
                <Avatar className="w-8 h-8 rounded-full bg-gray-300">
                  <AvatarImage src={asset.icon} alt={asset.label} />
                </Avatar>
              </div>
            </>
          )}

<div className="absolute top-1/2 left-full transform -translate-y-1/2 ml-2 px-3 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity">

            {asset.label} on {network.label}
          </div>
        </>
      )}
    </div>
  );
};

export default NetworkAsset;
