import { ModeToggle } from "@/components/theme/ModeToggle";
import { animated, useSpring } from "@react-spring/web";
import { useState } from "react";
import { Link } from "react-scroll";

export const NavBar = () => {
  const [expandMenu, setExpandMenu] = useState(false);
  const slideInAnimation = useSpring({
    from: { transform: "translateY(-100%)" },
    to: { transform: "translateY(0)" },
    config: { tension: 170, friction: 26 },
  });

  return (
    <animated.header
      style={slideInAnimation}
      className="flex flex-wrap md:justify-start md:flex-nowrap w-full text-sm sticky top-0 z-50 mx-auto max-w-[85rem]"
    >
      <nav
        className="mt-6 absolute mx-auto  w-full bg-white border border-gray-200 rounded-[36px] py-2 px-4 md:flex md:items-center md:justify-between md:py-0 md:mx-6 lg:px-8 xl:mx-auto dark:bg-gray-800 dark:border-gray-700"
        aria-label="Global"
      >
        <div className="flex items-center justify-between w-full">
          <Link
            className="flex items-center space-x-3 rtl:space-x-reverse cursor-pointer"
            to="hero-section"
            smooth={true}
            duration={500}
            onClick={() => setExpandMenu(false)}
          >
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Ledgernalysis
            </span>
            <img src="/logo-small.svg" className="h-6" alt="Logo" />
          </Link>
          <div className="flex items-center">
            <div className="md:hidden">
              <button
                type="button"
                className="hs-collapse-toggle w-8 h-8 flex justify-center items-center text-sm font-semibold rounded-full border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
                onClick={() => setExpandMenu(!expandMenu)}
              >
                <svg
                  className={`${expandMenu ? "hidden" : "block"} flex-shrink-0 w-4 h-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
                <svg
                  className={`${expandMenu ? "block" : "hidden"} flex-shrink-0 w-4 h-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          id="navbar-collapse-with-animation"
          className={`hs-collapse ${expandMenu ? "block" : "hidden"} md:block overflow-hidden transition-all duration-300 basis-full grow`}
        >
          <div className="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:items-center md:justify-end md:gap-y-0 md:gap-x-6 md:mt-0 md:ps-7">
            <Link
              className="font-semibold text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500 cursor-pointer"
              to="card-section"
              smooth={true}
              duration={500}
              onClick={() => setExpandMenu(false)}
            >
              Services
            </Link>
            <Link
              className="font-semibold text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500 cursor-pointer"
              to="integration-section"
              smooth={true}
              duration={500}
              onClick={() => setExpandMenu(false)}
            >
              Integrations
            </Link>
            <Link
              className="font-semibold text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500 cursor-pointer"
              to="feature-section"
              smooth={true}
              duration={500}
              onClick={() => setExpandMenu(false)}
            >
              What We Offer
            </Link>
            <Link
              className="font-semibold text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500 cursor-pointer"
              to="about-us-section"
              smooth={true}
              duration={500}
              onClick={() => setExpandMenu(false)}
            >
              About Us
            </Link>
            <Link
              className="font-semibold text-gray-500 hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500 cursor-pointer"
              to="contact-us-section"
              smooth={true}
              duration={500}
              onClick={() => setExpandMenu(false)}
            >
              Contact Us
            </Link>
            <div className="flex items-center justify-between w-full md:w-auto border-t pt-4 md:border-none md:pt-0 dark:border-gray-700 gap-x-4 sm:mb-2">
              <a
                className="flex items-center gap-x-2 font-semibold text-gray-500 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-500 cursor-pointer pt-2"
                href="/auth"
              >
                {" "}
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                  <circle cx="12" cy="7" r="4" />
                </svg>
                Log in
              </a>
              <div className="pt-2">
                <ModeToggle />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </animated.header>
  );
};
