import { Asset } from "@/types/report.type";
import React from "react";
import { Avatar, AvatarImage } from "../ui/avatar";

interface AvatarWithLabelProps {
  asset?: Asset;
}

const NetworkAsset: React.FC<AvatarWithLabelProps> = ({ asset }) => {
  if (!asset) return null;

  return (
    <div className="relative group w-6 h-8">
      <div className="absolute top-0 left-0">
        <Avatar className="w-5 h-5 rounded-full bg-gray-300 p-1">
          <AvatarImage src={asset.icon} alt={asset.label} />
        </Avatar>
      </div>

      <div className="absolute top-1/2 left-full transform -translate-y-1/2 ml-2 px-3 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity">
        {asset.label}
      </div>
    </div>
  );
};

export default NetworkAsset;