import { Steps } from '@/components/stepper/Steps'
import { MultiStepContextProvider } from '@/context/multistep'
import { ReactNode } from 'react'

interface MultiStepFormLayout {
  children: ReactNode
}

export default function MultiStepFormLayout({ children }: MultiStepFormLayout) {
  return (
    <MultiStepContextProvider>
      <div className="flex-col rounded-md  lg:grid lg:max-w-[850px] lg:grid-cols-3 lg:grid-rows-5 lg:items-center ">
        <Steps />
        {children}
      </div>
    </MultiStepContextProvider>
  )
}