import apiService from "./apiService";

const getPaginationParams = (limit: number, page: number) => {
  return { limit, page };
};

export const getAllReportGroups = async (
  workspaceId: string,
  limit: number,
  page: number
) => {
  try {
    const params = { workspaceId, ...getPaginationParams(limit, page) };
    return await apiService.get(`/getReportGroups`, { params });
  } catch (error) {
    console.error("Error fetching report groups:", error);
    throw error;
  }
};

export const getAllReports = async (reportGroupId: string) => {
  try {
    return await apiService.get(`/getReportEntries`, {
      params: { reportGroupId },
    });
  } catch (error) {
    console.error("Error fetching reports:", error);
    throw error;
  }
};

export const getReportsPage = async (
  reportGroupId: string,
  limit: number,
  page: number
) => {
  try {
    const params = { reportGroupId, ...getPaginationParams(limit, page) };
    return await apiService.get(`/getReportEntries`, { params });
  } catch (error) {
    console.error("Error fetching report page:", error);
    throw error;
  }
};

export const createReportWithSourceGroups = async (
  sourceGroupIds: string[],
  datetime?: number[]
) => {
  try {
    return await apiService.put(`/sourceGroupToReportGroup`, {
      sourceGroupIds,
      datetime,
    });
  } catch (error) {
    console.error("Error creating report group:", error);
    throw error;
  }
};

export const deleteReportGroup = async (reportGroupId: string) => {
  try {
    return await apiService.delete(`/deleteReportGroup`, {
      data: { reportGroupIds: [reportGroupId] },
    });
  } catch (error) {
    console.error("Error deleting report group:", error);
    throw error;
  }
};

export const getTransactions = async (
  reportId: string,
  limit: number,
  page: number
) => {
  try {
    return await apiService.post(`/txs`, {
      reportIds: [reportId],
      limit,
      page,
    });
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

export const getGraphData = async (reportId: string) => {
  try {
    return await apiService.post(`/graph`, { reportIds: [reportId] });
  } catch (error) {
    console.error("Error fetching graph data:", error);
    throw error;
  }
};

export const downloadTx = async (reportId: string) => {
  try {
    return await apiService.post(`/downloadReport`, {
      reportId,
      options: {
        txs_detail: true,
      },
    });
  } catch (error) {
    console.error("Error downloading transaction report:", error);
    throw error;
  }
};

export const downloadReportGroup = async (reportGroupId: string) => {
  try {
    return await apiService.post(`/downloadReport`, { reportGroupId });
  } catch (error) {
    console.error("Error downloading report group:", error);
    throw error;
  }
};
