import NetworkGrid from "@/components/cards/NetworkGrid";
import { ASSETS } from "@/config/general";
import { animated, useSpring } from "@react-spring/web";
import CountUp from "react-countup";

export const IntegrationsSection = () => {
  const totalAssetsCount = ASSETS.length;
  const titleAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 200, friction: 20 },
  });

  const subtitleAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { tension: 200, friction: 20 },
    delay: 300,
  });

  return (
    <section
      id="services"
      className="pt-20 bg-gray-50 dark:bg-gray-900 lg:pb-[90px] lg:pt-[120px]"
    >
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <animated.h2
                style={titleAnimation}
                className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]"
              >
                Supported Integrations
              </animated.h2>
              <animated.h4
                style={subtitleAnimation}
                className="text-xl text-primary"
              >
                Supporting
                <span className="text-primary mx-1 font-semibold text-2xl relative inline-block stroke-current">
                  <CountUp end={totalAssetsCount} duration={2} /> integrations
                  <svg
                    className="absolute -bottom-0.5 w-full max-h-1.5"
                    viewBox="0 0 55 5"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </span>
                and growing rapidly.
              </animated.h4>
            </div>
          </div>
        </div>
        <NetworkGrid />
      </div>
    </section>
  );
};
