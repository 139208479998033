import { useMultiContext } from "@/context/multistep";

interface StepItemProps {
  infos: {
    num: number;
    title: string;
    description: string;
  };
}

export function StepItem({ infos }: StepItemProps) {
  const { step } = useMultiContext();
  const maxStep = 5; // You can replace this with a dynamic step count if available
  const isFinished = step === maxStep && infos.num === maxStep - 1;

  return (
    <li className="flex items-center gap-4 uppercase">
      <span
        className={`flex w-10 h-10 items-center justify-center rounded-full border-2 font-medium ${
          step === infos.num || isFinished ? "border-indigo-300 bg-indigo-300 text-black" : "border-gray-300 text-gray-400"
        }`}
      >
        {infos.num}
      </span>
      <div className="hidden flex-col lg:flex">
        <p className="text-sm text-gray-400">{infos.title}</p>
        <p>{infos.description}</p>
      </div>
    </li>
  );
}