import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { createSourceGroup, editSourceGroup } from "@/services/sourceService";
import { FC, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { LOADING_TEXT } from "@/config/general";
import useAuthStore from "@/stores/auth.store";
import useSourcesStore from "@/stores/sources.store";
import useWorkspaceStore from "@/stores/workspaces.store";
import { SourceGroup } from "@/types/sources.type";
import { SourceGroupSchema } from "@/zod/source.zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import SelectGroupType from "../ledgernalysis/SelectGroupType";
import { useToast } from "../ui/use-toast";

interface SourceFormProps {
  sourceId: string | null;
  onClose?: () => void;
}

export const SourceForm: FC<SourceFormProps> = ({ sourceId, onClose }) => {
  const { toast } = useToast();

  const [source, setSource] = useState<SourceGroup | null>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const email = useAuthStore((state) => state.user?.email);
  const workspaceId = useWorkspaceStore((state) => state.selectedWorkspaceId);

  // Fetch the source groups and ensure sourceGroups is always an array
  const [sourceGroups, fetchAllSourceGroups] = useSourcesStore((state) => [
    state.sourceGroups ?? [], // Ensure sourceGroups is an array
    state.fetchAllSourceGroups,
  ]);

  // Initialize form with validation schema
  const sourceForm = useForm<SourceFormType>({
    resolver: zodResolver(SourceGroupSchema),
    defaultValues: {
      sourceName: "",
      groupType: "BALA",
    },
  });

  // Set source from sourceId when sourceId or sourceGroups change
  useEffect(() => {
    if (sourceId && Array.isArray(sourceGroups)) {
      const foundSource = sourceGroups.find(
        (source) => source.sourceGroupId === sourceId
      ) || null;
      setSource(foundSource);
      if (foundSource) {
        sourceForm.reset(foundSource); // Reset form with the found source data
      }
    }
  }, [sourceId, sourceGroups, sourceForm]);

  type SourceFormType = z.infer<typeof SourceGroupSchema>;

  const onSubmit = async (data: SourceFormType) => {
    if (!email) return;

    try {
      setLoading(true);
      let response: unknown;

      if (sourceId) {
        response = await editSourceGroup(sourceId, data.sourceName);
        console.log("Updated source", response);
      } else {
        response = await createSourceGroup(
          workspaceId,
          data.sourceName,
          data.groupType
        );
      }

      toast({
        title: `Source ${sourceId ? "Updated" : "Created"}`,
        description: sourceId
          ? "Edit existing entries of your source."
          : "You can now add entries to your source.",
      });

      await fetchAllSourceGroups(100, 1);

      if (onClose) onClose();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...sourceForm}>
      <div className="mt-4">
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
      <form onSubmit={sourceForm.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={sourceForm.control}
          name="groupType"
          render={({ field }) => (
            <FormItem
              className={source?.count ? "opacity-50 cursor-not-allowed" : ""}
            >
              <FormLabel>
                <div>
                  Source Type
                  <p className="text-xs font-normal py-2">
                    *Sources can only contain entries of a single type.
                  </p>
                </div>
              </FormLabel>
              <FormControl>
                <SelectGroupType
                  disabled={source && source?.count > 0 ? true : false}
                  field={field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={sourceForm.control}
          name="sourceName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Source Name</FormLabel>
              <FormControl>
                <Input placeholder="Enter source name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {loading ? (
          <Button disabled className="w-full" size="lg">
            <span className="loading-icon" /> {/* Add loading icon */}
            {LOADING_TEXT}
          </Button>
        ) : (
          <Button type="submit" className="w-full" size="lg">
            {sourceId ? "Update Source" : "Create Source"}
          </Button>
        )}
      </form>
    </Form>
  );
};