import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import React, { useEffect, useState } from "react";

import { Input } from "../ui/input";

interface NetworkOptionCardProps {
  network: {
    value: string;
    img: string;
    label: string;
  };
  onSelect: (value: string) => void;
}

interface SelectNetworkDialogProps {
  networks: any[];
  onValueChange: (value: string) => void;
  selectedValue: string | null;
  title: string;
  description: string;
}

const SelectNetworkDialog = ({
  networks,
  onValueChange,
  selectedValue,
  title,
  description,
}: SelectNetworkDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredNetworks, setFilteredNetworks] = useState(networks);

  const handleSelect = (value: string) => {
    onValueChange(value);
    setDialogOpen(false);
  };

  useEffect(() => {
    const filtered = networks.filter((network) =>
      network?.label?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setFilteredNetworks(filtered);
  }, [networks, searchValue]);

  const assetdfd =  networks.find((n) => n.value === selectedValue)
console.log("Selected Asset", assetdfd)
  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <button onClick={() => setDialogOpen(true)} className="w-full">
          {selectedValue ? (
            <NetworkOptionCard
              network={networks.find((n) => n.value === selectedValue)}
              onSelect={() => {}}
            />
          ) : (
            <div className="inline-block p-2 m-1 border dark:border-gray-700 dark:hover:border-blue-300  rounded-lg cursor-pointer hover:border-blue-500 w-full">
              {title || "Select network"}
            </div>
          )}
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{title || "Select Network"}</DialogTitle>
        <DialogDescription>
          {description || "Choose one of the available networks."}
        </DialogDescription>
        <Input
          className="w-full mt-2"
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className="max-h-[34rem] mt-2 overflow-y-auto">
          <div className="grid grid-cols-4 gap-2 p-2">
            {filteredNetworks.map((network: any) => (
              <NetworkOptionCard
                key={network.value}
                network={network}
                onSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const NetworkOptionCard = React.forwardRef<
  HTMLDivElement,
  NetworkOptionCardProps
>(({ network, onSelect }, ref) => (
  <div
    ref={ref as React.RefObject<HTMLDivElement>}
    className="inline-block p-2 m-1 border dark:border-gray-700 dark:hover:border-blue-400 cursor-pointer rounded-lg hover:border-blue-500"
    onClick={() => onSelect(network?.value)}
    role="option"
    aria-selected="false"
    tabIndex={0}
  >
    <img
      src={network?.img}
      alt={`${network?.label} logo`}
      className="block mx-auto h-12 w-12"
    />
    <div className="text-center text-sm">{network?.label}</div>
  </div>
));

interface Network {
  value: string;
  img: string;
  label: string;
}

interface NetworkSelectProps {
  networks: Network[];
  onValueChange: (value: string) => void;
  title: string;
  description: string;
  initialValue: string;
}

const NetworkSelect = ({
  networks,
  onValueChange,
  title,
  description,
  initialValue,
}: NetworkSelectProps) => {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(
    initialValue
  );

  const handleSelect = (value: any) => {
    setSelectedValue(value);
    onValueChange(value);
    // dismiss the dialog
  };

  return (
    <SelectNetworkDialog
      networks={networks}
      onValueChange={handleSelect}
      selectedValue={selectedValue}
      title={title}
      description={description}
    />
  );
};

export default NetworkSelect;

// Usage example:
// import BLOCKCHAIN_NETWORKS from wherever the networks are exported
{
  /* <NetworkSelect networks={BLOCKCHAIN_NETWORKS} onValueChange={(value) => console.log(value)} /> */
}
