import { Database, LayoutDashboard, PieChart } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import useSourcesStore from "@/stores/sources.store";
import useWorkspaceStore from "@/stores/workspaces.store";
import WorkspaceSwitcher from "../ledgernalysis/WorkspaceSelection";

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export function Sidebar({ className = "" }: SidebarProps) {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [workspaces = [], loadingWorkspace] = useWorkspaceStore((state) => [
    state.workspaces,
    state.loading,
  ]);
  const sourceGroups = useSourcesStore((state) => state.sourceGroups) || [];
  const sourceGroupData = sourceGroups && sourceGroups;
  const hasSourceEntries =
    Array.isArray(sourceGroupData) &&
    sourceGroupData.some((group) => group.count > 0);

  return (
    <div className={cn("pb-12", className)}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <h2 className="mb-2 text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-100">
            Workspace
          </h2>
          <div className="space-y-2">
            <div className="my-3">
              <WorkspaceSwitcher />
            </div>
            <Separator className="my-3 dark:bg-gray-700" />
            <Button
              variant={path.includes("/dashboard") ? "secondary" : "ghost"}
              className="w-full justify-start text-gray-900 dark:text-gray-100"
              onClick={() => navigate("/dashboard")}
            >
              <LayoutDashboard
                size={18}
                strokeWidth={1.75}
                className="mr-2 dark:text-gray-100"
              />
              Dashboard
            </Button>
            <Button
              variant={path.includes("/sources") ? "secondary" : "ghost"}
              className="w-full justify-start text-gray-900 dark:text-gray-100"
              onClick={() => navigate("/sources")}
              disabled={loadingWorkspace || workspaces.length === 0}
            >
              <Database
                size={18}
                strokeWidth={1.75}
                className="mr-2 dark:text-gray-100"
              />
              Sources
            </Button>
            <Button
              variant={path.includes("/reports") ? "secondary" : "ghost"}
              className="w-full justify-start text-gray-900 dark:text-gray-100"
              onClick={() => navigate("/reports")}
              disabled={
                loadingWorkspace || workspaces.length === 0 || !hasSourceEntries
              }
            >
              <PieChart
                size={18}
                strokeWidth={1.75}
                className="mr-2 dark:text-gray-100"
              />
              Reports
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
