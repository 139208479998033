import { animated, useSpring } from "@react-spring/web";
import { CircleDot, FastForward, KeyRound, Scale } from "lucide-react";
import { FC } from "react";

interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  details: string;
  delay: number;
  backgroundShade: string;
}

const Card: FC<ServiceCardProps> = ({
  icon,
  title,
  details,
  delay,
  backgroundShade,
}) => {
  const fadeInProps = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { mass: 1, tension: 210, friction: 20, clamp: true },
    delay,
  });

  return (
    <animated.div
      style={fadeInProps}
      className={`group flex gap-y-6 w-full h-full rounded-lg p-8 transition-all ${backgroundShade} relative overflow-hidden`}
    >
      <div className="absolute inset-0 z-0">
        <img
          src="/card-overlay.svg"
          alt="Background overlay"
          className="w-full h-full object-cover opacity-20"
        />
      </div>
      <div className="flex-shrink-0 w-8 h-8 text-gray-100 mt-0.5 me-6 dark:text-gray-200 z-10">
        {icon}
      </div>
      <div className="z-10">
        <h3 className="block font-bold text-gray-100 dark:text-white mb-2">
          {title}
        </h3>
        <p className="text-gray-200 dark:text-gray-200">{details}</p>
      </div>
    </animated.div>
  );
};

export const HeroSection = () => {
  const titleAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { mass: 1, tension: 170, friction: 26 },
    delay: 100,
  });

  return (
    <div className="relative isolate overflow-hidden bg-gray-100 dark:bg-gray-900 hero-bg">
      <div
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 dark:stroke-gray-50/10 [mask-image:radial-gradient(100%_100%_at_top_right,white, white)]"
        aria-hidden="true"
      >
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 dark:stroke-gray-50/10 [mask-image:radial-gradient(100%_100%_at_top_right,white, white)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="pattern"
              width="200"
              height="200"
              x="100%"
              y="-1"
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none"></path>
            </pattern>
          </defs>
          <svg
            x="50%"
            y="-1"
            className="overflow-visible fill-gray-400/10 dark:fill-gray-700/10"
          >
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth="0"
            ></path>
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth="0"
            fill="url(#pattern)"
          ></rect>
        </svg>
        <div
          className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style={{
              clipPath: `polygon(
                         73.6% 51.7%,
                         91.7% 11.8%,
                         100% 46.4%,
                         97.4% 82.2%,
                         92.5% 84.9%,
                         75.7% 64%,
                         55.3% 47.5%,
                         46.5% 49.4%,
                         45% 62.9%,
                         50.3% 87.2%,
                         21.3% 64.1%,
                         0.1% 100%,
                         5.4% 51.1%,
                         21.4% 63.9%,
                         58.9% 0.2%,
                         73.6% 51.7%
                       )`,
            }}
          />
        </div>{" "}
      </div>
      <div className="mt-[-50px] flex h-screen items-center justify-center flex-col">
        <animated.div
          style={titleAnimation}
          className="max-w-full flex-shrink-0 px-4 text-center lg:mx-0 lg:max-w-3xl lg:pt-8"
        >
          <h1 className="mt-10 text-5xl font-bold tracking-tight text-black dark:text-white sm:text-6xl pt-10">
            Demystify your <span className="text-sky-500"> digital assets</span>{" "}
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-900 dark:text-gray-300">
            World-class accounting, audit and tax software for your digital
            assets
          </p>
        </animated.div>
        <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-24 mb-0">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <Card
              title="Streamlined Portfolio"
              details="Dive deep into transactional data."
              icon={<FastForward size={36} strokeWidth={1.75} />}
              delay={0}
              backgroundShade="bg-green-400 dark:bg-green-800"
            />
            <Card
              title="Precision Analysis"
              details="Rediscover historic balances anytime."
              icon={<CircleDot size={36} strokeWidth={1.75} />}
              delay={200}
              backgroundShade="bg-blue-400 dark:bg-blue-700"
            />
            <Card
              title="Trust Verification"
              details="Digital signature verification over a range of protocols."
              icon={<KeyRound size={36} strokeWidth={1.75} />}
              delay={400}
              backgroundShade="bg-mint-600 dark:bg-mint-700"
            />
            <Card
              title="Risk Management"
              details="Determine sanction violation risks of assets"
              icon={<Scale size={36} strokeWidth={1.75} />}
              delay={600}
              backgroundShade="bg-sky-500 dark:bg-sky-950"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
