/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn, formatNumber, useWindowDimensions } from "@/lib/utils";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { useEffect } from "react";

import {
  ArrowDownSquare,
  ArrowUpDown,
  Loader2,
  MoreVertical,
} from "lucide-react";
import { Avatar, AvatarImage } from "../ui/avatar";

import { CellIcons } from "@/types/tables.type";
import _ from "lodash";
import { useState } from "react";
import { LoadingButton } from "../misc/LoadingButton";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { DataTablePagination } from "./CustDataTablePagination";
import "./dataTable.css";

interface Column {
  id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell?: (value: any) => any;
  width?: number;
}
interface RowAction<TData> {
  onClick: (row: TData) => void;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  disabled?: (row: TData) => boolean;
  variant?: "danger" | "success";
}

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRows: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

interface DataTableProps<TData> {
  columns: Column[];
  columnIcons?: CellIcons[];
  data: TData[];
  globalFilter?: {
    placeholder: string;
    accessorKey: string;
  };
  actionButtons?: {
    onClick: () => void;
    text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
  }[];
  rowActions?: RowAction<TData>[];
  loading?: boolean;
  fetchMoreDataLoader?: boolean;
  fetchMoreData?: () => void;
  pagination: PaginationProps;
}

function generateColumnDefs<TData>(
  columns: Column[],
  actions: RowAction<TData>[],
  columnIcons?: CellIcons[]
): ColumnDef<TData, any>[] {
  const WindowDimensions = useWindowDimensions();

  const isSmallScreen = () => WindowDimensions.width < 768;

  const colDefs: ColumnDef<TData, any>[] = columns.map((columnConfig) => ({
    accessorKey: columnConfig.id,
    id: columnConfig.id,
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {columnConfig.name}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: (props) => {
      if (columnConfig.name === "Secret") {
        return "*****************";
      } else {
        const value = props.getValue();
        if (
          [
            "Result.total",
            "accounts_change.total",
            "accounts_before.total",
            "accounts_before.holdings",
            "accounts_after.holdings",
            "accounts_after.total",
            "accounts_before.change",
            "accounts_change.holdings",
          ].includes(columnConfig.id)
        ) {
          return formatNumber(value);
        }
        const column = columnIcons?.find(
          (columnIcon) => columnIcon.field === columnConfig.id
        );
        return (
          <>
            {column ? (
              <div className="flex align-middle">
                <Avatar className="mr-2 h-5 w-5">
                  <AvatarImage
                    src={column.icons.find((icon) => icon.value === value)?.img}
                    alt={"t"}
                  />
                </Avatar>
                <p>{columnConfig.cell ? columnConfig.cell(value) : value}</p>
              </div>
            ) : (
              <>{columnConfig.cell ? columnConfig.cell(value) : value}</>
            )}
          </>
        );
      }
    },
    size: columnConfig.width ?? 400,
  }));

  if (actions.length) {
    colDefs.push({
      id: "actions",
      header: "Actions",
      size: 100 + actions.length * 80,
      cell: ({ row }) => {
        return (
          <div className="flex items-center space-x-2">
            {isSmallScreen() ? (
              <>{RowActionMobileButtons(actions, row)}</>
            ) : (
              <>{RowActions(actions, row)}</>
            )}
          </div>
        );
      },
    });
  }

  return colDefs;
}

function DataTable<TData>({
  columns,
  columnIcons,
  data,
  globalFilter,
  actionButtons = [],
  rowActions = [],
  loading = false,
  fetchMoreDataLoader = false,
  fetchMoreData,
  pagination,
}: DataTableProps<TData>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const tableInstance = useReactTable({
    data,
    columns: generateColumnDefs(columns, rowActions, columnIcons),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    columnResizeMode: "onChange",
    state: {
      sorting,
      columnFilters,
      pagination: {
        pageIndex: pagination.currentPage - 1,
        pageSize: pagination.pageSize,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const updatedState = updater(tableInstance.getState().pagination);
        pagination.onPageChange(Number(updatedState.pageIndex) + 1); // Since `pageIndex` is 0-based
        pagination.onPageSizeChange(Number(updatedState.pageSize));
      } else {
        pagination.onPageChange(Number(updater.pageIndex) + 1);
        pagination.onPageSizeChange(Number(updater.pageSize));
      }

      if (fetchMoreData) {
        fetchMoreData();
      }
    },
    initialState: {
      pagination: {
        pageSize: pagination.pageSize,
      },
    },
    defaultColumn: {
      minSize: 100,
      maxSize: 1000,
      size: 400,
    },
  });

  useEffect(() => {
    tableInstance.setPageIndex(pagination.currentPage - 1);
  }, [pagination.currentPage, data, tableInstance]);
  return (
    <div className="w-full overflow-x-auto">
      <div className="my-2 flex flex-wrap">
        {globalFilter && (
          <div className="flex-grow mb-2">
            <Input
              placeholder={globalFilter.placeholder}
              className="w-full dark:bg-gray-900"
              value={
                (tableInstance
                  .getColumn(globalFilter.accessorKey)
                  ?.getFilterValue() as string) ?? ""
              }
              onChange={(event) =>
                tableInstance
                  .getColumn(globalFilter.accessorKey)
                  ?.setFilterValue(event.target.value)
              }
            />
          </div>
        )}
        {actionButtons.length > 0 && (
          <div className="flex mb-2">
            {actionButtons.map((action, index) => (
              <Button
                className="w-full ml-2"
                variant="outline"
                onClick={action.onClick}
                key={index}
              >
                <action.icon className="h-4 w-4 mr-2" />
                {action.text}
              </Button>
            ))}
          </div>
        )}
      </div>
      <div className="rounded-md p-2 dark:border dark:border-gray-600">
        <Table
          className="w-full min-w-max"
          style={{
            width: tableInstance.getCenterTotalSize(),
          }}
        >
          <TableHeader>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{ width: header.getSize() }}
                      className="relative dark:border-b-2 dark:border-b-gray-700"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!loading ? (
              tableInstance.getRowModel().rows?.length ? (
                tableInstance.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={row.id + index}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => {
                      if (rowActions.length > 0) {
                        rowActions[0].onClick(row.original);
                      }
                    }}
                    className="cursor-pointer"
                  >
                    {row.getVisibleCells().map((cell) => {
                      const val = _.get(row.original, cell.column.id);
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            cell.column.id !== "actions" && "pl-6",
                            "overflow-hidden break-all dark:border-b dark:border-gray-600"
                          )}
                          style={{ width: cell.column.getSize() }}
                        >
                          {val || cell.column.id === "actions"
                            ? flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )
                            : val !== 0
                              ? "N/A"
                              : "0"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No data.
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <Loader2 className="animate-spin mx-auto w-8 h-8" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="flex items-center justify-end space-x-2 py-4">
          {fetchMoreData && (
            <LoadingButton variant="outline" loading={fetchMoreDataLoader}>
              <Button variant="outline" onClick={() => fetchMoreData()}>
                <ArrowDownSquare strokeWidth={1.75} className="h-4 w-4 mr-2" />
                Fetch More Data
              </Button>
            </LoadingButton>
          )}
          {pagination && pagination.totalPages > 1 && (
            <DataTablePagination
              table={tableInstance}
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              totalRows={pagination.totalRows}
              onPageChange={pagination.onPageChange}
              onPageSizeChange={pagination.onPageSizeChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function RowActions<TData>(actions: RowAction<TData>[], row: Row<TData>) {
  return (
    <>
      {actions.length > 0 && (
        <>
          {actions.map((action, index) => {
            const variantClasses = {
              danger: "bg-red-500 text-white hover:bg-red-600",
              success: "bg-green-500 text-white hover:bg-green-600",
            };
            const variantClass = action.variant
              ? variantClasses[action.variant]
              : "";

            return (
              <Button
                key={index}
                className={variantClass}
                variant={action.variant ? "default" : "outline"}
                disabled={
                  action.disabled ? action.disabled(row.original) : false
                }
                onClick={(e) => {
                  e.stopPropagation();
                  return action.onClick(row.original);
                }}
              >
                <div className="flex items-center">
                  <action.icon className="h-4 w-4 mr-2" />
                  <p>{action.text}</p>
                </div>
              </Button>
            );
          })}
        </>
      )}
    </>
  );
}

function RowActionMobileButtons<TData>(
  actions: RowAction<TData>[],
  row: Row<TData>
) {
  return (
    <>
      {actions.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <MoreVertical strokeWidth={1.75} />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {actions.map((action, index) => (
              <DropdownMenuGroup key={index}>
                <DropdownMenuItem onClick={() => action.onClick(row.original)}>
                  <action.icon className="h-4 w-4 mr-2" />
                  <span>{action.text}</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            ))}
            <DropdownMenuSeparator />
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
}

export default DataTable;
