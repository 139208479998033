import { FC } from "react";
import { SourceEntriesForm } from "../forms/SourceEntriesForm";
import { GeneralModal } from "./GeneralModal";

interface SourceEntryModalProps {
  sourceEntryId?: string | null;
  sourceType?: string | null;
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: (value: boolean) => void;
}

export const SourceEntryModal: FC<SourceEntryModalProps> = ({
  sourceEntryId,
  sourceType,
  isEditDialogOpen,
  setIsEditDialogOpen,
}) => {
  const id = sourceEntryId ? sourceEntryId : null;
  const type = sourceType ? sourceType : "";

  return (
    <GeneralModal
      title={sourceEntryId ? "Edit a Source Entry" : "Create Source Entry"}
      description={
        sourceEntryId
          ? "You can edit the entry details here"
          : "You can create a new entry here"
      }
      isEditDialogOpen={isEditDialogOpen}
      setIsEditDialogOpen={setIsEditDialogOpen}
    >
      <SourceEntriesForm
        entryId={id}
        sourceType={type}
        setIsDialogOpen={setIsEditDialogOpen}
      />
    </GeneralModal>
  );
};
