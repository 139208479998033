import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import AssetLineChart from "@/components/charts/LineChartCard";
import AddressCell from "@/components/misc/AddressCell";
import NetworkAsset from "@/components/misc/NetworkAsset";
import DataTable from "@/components/table/CustomDataTable";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useApiWebSocketCtx } from "@/context/context";
import { formatArrayLongDate, getNameBySymbol, lookupAsset } from "@/lib/utils";
import useReportsStore from "@/stores/reports.store";
import { AssetData, ChartDataItem, DownloadResponse, TransactionData } from "@/types/report.type";
import {
  ArrowDown,
  ArrowLeft,
  ArrowUp,
  Clock,
  Download,
  Loader2,
} from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "./Reports";

function ReportEntry() {
  const navigate = useNavigate();
  const { reportGroupId, reportId } = useParams();
  const reportEntries = useReportsStore((state) => state.reports);

  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );
  const getTransactions = useReportsStore((state) => state.getTransactions);
  const getGraphData = useReportsStore((state) => state.getGraphData);
  const loading = useReportsStore((state) => state.loading);
  const { selectedReportGroupData, report } = useReportsStore();

  const [transactions, setTransactions] = useState<TransactionData[]>([]);
  const downloadTx = useReportsStore((state) => state.downloadTx);
  const [graphData, setGraphData] = useState<AssetData[]>([]);
  const [downloadText, setDownloadText] = useState<string>("");
  const [reportFile, setReportFile] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { toast } = useToast();
  const { socket } = useApiWebSocketCtx();

  const transactionsCols = useMemo(
    () => [
      {
        id: "blocktime",
        name: "Timestamp",
        cell: (val: number) => {
          const date = new Date(val);
          return date.toLocaleString();
        },
        width: 178,
      },
      {
        id: "transaction",
        name: "Transaction ID",
        cell: (val: string) => <AddressCell fullAddress={val} />,
        width: 180,
      },
      {
        id: "before",
        name: "Balance Before",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 8,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "after",
        name: "Balance After",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 8,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "change",
        name: "Change",
        cell: (val: string) => {
          const changeValue = parseFloat(val);
          const Icon =
            changeValue > 0 ? ArrowUp : changeValue < 0 ? ArrowDown : null;
          const textColor =
            changeValue > 0
              ? "text-green-600"
              : changeValue < 0
                ? "text-red-600"
                : "text-black dark:text-gray-200";

          return (
            <span className={`${textColor} font-semibold flex items-center`}>
              {Icon && <Icon className="mr-1" size={16} />}
              {Math.abs(changeValue)}
            </span>
          );
        },
        width: 230,
      },
    ],
    []
  );

  const fetchTransactions = useCallback(
    async (page: number, limit: number) => {
      const response = await getTransactions(reportId || "", limit, page);
      if (response && response.data && response.data.length > 0) {
        setTransactions(response.data);
        setTotalPages(response.pages);
        setTotalRows(response.max);
        response.limit && setPageSize(response.limit);
      }
    },
    [getTransactions, reportId]
  );

  useEffect(() => {
    const fetchGraphData = async () => {
      setSelectedReportGroup(reportGroupId || "");
      const tempGraphData: ChartDataItem[] = await getGraphData(reportId || "");
      const formattedData = tempGraphData.map((item) => ({
        datetime: item.datetime,
        after: item.after,
        asset: item.asset,
      }));
      setGraphData(formattedData);
    };
    fetchGraphData();
  }, [reportGroupId, reportId, setSelectedReportGroup, getGraphData]);

  useEffect(() => {
    fetchTransactions(currentPage, pageSize);
  }, [pageSize, fetchTransactions, currentPage]);

  useEffect(() => {
    if (!socket) return;

    socket.on("reportDownload", (data) => {
      setReportFile(data?.data || "");
    });

    return () => {
      socket.off("reportDownload");
    };
  }, [socket]);

  useEffect(() => {
    if (reportFile) {
      const link = document.createElement("a");
      link.href = reportFile;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    }
    setIsButtonDisabled(false);
    setReportFile("");
    setDownloadText("");
  }, [reportFile]);

  const snapshotFirstEntryDates = reportEntries[0]?.entry?.datetime;
  const snapshotDate = snapshotFirstEntryDates
    ? formatArrayLongDate(snapshotFirstEntryDates)
    : "";

  const emitReportDownload = async () => {
    setIsButtonDisabled(true);
    toast({
      title: "Generating your report",
      description:
        "Your report is being generated and will be downloaded as soon as it's ready.",
      duration: 5000,
    });
    socket.emit("register", {
      reference: reportId,
      type: "csv",
    });
      await downloadAdvancedReportTransactions();

  };

  const groupType = selectedReportGroupData?.groupType || "";

  const reportGroupUrl = `/reports/${selectedReportGroupData?.reportGroupId}`;
  const handlePageChange = (page: number) =>
    setCurrentPage(!isNaN(page) ? page : 1);
  const handlePageSizeChange = (size: number) =>
    setPageSize(!isNaN(size) && size > 0 ? size : 100);

  const downloadAdvancedReportTransactions = async () => {
    setDownloadText("Generating CSV...");
    const response: DownloadResponse = await downloadTx(
      reportId || ""
    );
    if (
      response &&
      response.error === false &&
      response.progress === 100
    ) {

      setReportFile(response.data)
    }
  };

  return (
    <div>
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-2 lg:py-2 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-pie-chart h-4 w-4"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Dashboard</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to="/reports">Reports</Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to={reportGroupUrl}>
                  {selectedReportGroupData?.reportName}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  Report Entries: {report?.entry.asset}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            {report && (
              <NetworkAsset
                asset={lookupAsset(report?.entry.asset)}
                network={lookupAsset(report?.entry.network)}
              />
            )}
            <h1 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl lg:leading-[1.1]">
              Report: {selectedReportGroupData?.reportName}
            </h1>
          </div>
          <Button
            className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-8 rounded-md px-3 text-xs ml-auto"
            title="Go Back to Report Entries"
            variant="outline"
            onClick={() =>
              navigate(`/reports/${selectedReportGroupData?.reportGroupId}`)
            }
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Entries
          </Button>
        </div>
        <div className="flex items-center bg-accent">
          <span className="ml-0 border px-2 rounded-full text-sm">
            {getNameBySymbol(groupType)}
          </span>
          <p className="text-md font-semibold text-gray-800 dark:text-gray-100 ml-4">
            Status:
          </p>
          <span className="ml-2">
            {formatStatus(report?.status || "P", report?.message)}
          </span>

          {["BALA", "EXCH"].includes(groupType) && snapshotDate && (
            <span className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs">
              <span className="relative group inline-flex items-center">
                <Clock className="h-4 w-4 m-2" />
                <span className="absolute left-0 top-full mt-1 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  Snapshot Timestamp
                </span>
              </span>
              {snapshotDate}
            </span>
          )}
        </div>
        <div className="flex w-full items-center justify-start gap-2 py-2">
          {transactions && transactions.length > 0 && (
            <Button
              title="Download Report"
              variant="outline"
              disabled={isButtonDisabled}
              onClick={emitReportDownload}
            >
              {downloadText ? (
                <Loader2 className="h-4 w-4 mr-2" />
              ) : (
                <Download className="h-4 w-4 mr-2" />
              )}
              {downloadText || "Download Report"}
            </Button>
          )}
        </div>
      </section>

      {graphData && graphData.length > 0 && (
        <DashboardMainCard
          header={`Asset: ${report?.entry.asset}`}
          description={report?.entry.address}
        >
          <AssetLineChart data={graphData} />
        </DashboardMainCard>
      )}
      <DashboardMainCard
        header={"Transactions"}
        description={"Report data entry transactions"}
      >
        {transactions.length > 0 ? (
          <DataTable
            columns={transactionsCols}
            data={transactions}
            loading={loading}
            pagination={{
              currentPage,
              pageSize,
              totalPages,
              totalRows,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
            actionButtons={
              transactions && transactions.length > 0 && !isButtonDisabled
                ? [
                    {
                      onClick: emitReportDownload,
                      text: downloadText || "Download Report",
                      icon: downloadText ? Loader2 : Download,
                    },
                  ]
                : []
            }
          />
        ) : (
          <p>No transactions available.</p>
        )}
      </DashboardMainCard>
    </div>
  );
}

export default ReportEntry;
