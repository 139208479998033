/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Activity,
  ArrowUpRight,
  DollarSign,
  AreaChart,
  ChartNoAxesCombined,
} from "lucide-react";

import AnimatedCard from "@/components/cards/AnimatedCard";
import { PieChartCard } from "@/components/cards/PieChartCard";
import { AreaChartCard } from "@/components/cards/AreaChartCard";
import { DashboardGuideCard } from "@/components/cards/DashboardGuideCard";
import DashboardWorkspaceCard from "@/components/cards/DashboardWorkspaceCard";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import useWorkspaceStore from "@/stores/workspaces.store";
import { animated, useSpring } from "@react-spring/web";
import { useMemo } from "react";
import { formatBalance } from "@/lib/utils";
import TransactionsTable from "@/components/cards/TransactionsTable";
import { TokenAllocation } from "@/types/dashboard.type";
interface RecentTx {
  reportId: string;
  asset: string;
  after: string;
  price: string;
  blocktime: number;
}


  const getTotalNumberOfTokens = (tokenAllocation: TokenAllocation | undefined): number => {
    if (!tokenAllocation) {
      return 0; 
    }
    return Object.keys(tokenAllocation).length;
  };
const isFeatureReady = false;
export default function Dashboard() {
  const [workspaces, getSelectedWorkspace, loadingProfile] = useWorkspaceStore(
    (state) => [state.workspaces, state.getSelectedWorkspace, state.loading]
  );
  const getSelectedWorkspaceDashboard = useWorkspaceStore(
    (state) => state.getSelectedWorkspaceDashboard
  );

  const ws = getSelectedWorkspaceDashboard();

  const selectedWorkspace = getSelectedWorkspace();
  const data = ws && ws;
  const portfolioValue = useMemo(() => data && data?.portfolioValue, [data]);
  const totalReports = useMemo(() => data && data?.totalReports, [data]);
  // const totalTokens = useMemo(
  //   () => data && data.tokenAllocation.length,
  //   [data]
  // );

  // const tokenAllocation = useMemo(
  //   () => (data && data.tokenAllocation) || [],
  //   [data]
  // );

  const totalNumberOfTokens = getTotalNumberOfTokens(data?.tokenAllocation);


  const totalReportEntries = useMemo(
    () => data && data.totalReportEntries,
    [data]
  );
  const recentTransactions = useMemo(() => {
    return (
      data &&
      data.recentTransactions.map((transaction: RecentTx) => ({
        ...transaction,
      }))
    );
  }, [data]);

  // const totalAssets2 = useMemo(
  //   () => (tokenAllocation ? Object.keys(tokenAllocation).length : 0),
  //   [tokenAllocation]
  // );

  // const totalAssets = useMemo(() => {
  //   return tokenAllocation && Object.entries(tokenAllocation) ? Object.entries(tokenAllocation)?.length : 0;
  // }, [tokenAllocation]);

  const cardSpring = useSpring({
    from: { opacity: 0, transform: "scale(0.95) translateY(20px)" },
    to: { opacity: 1, transform: "scale(1) translateY(0px)" },
    config: { tension: 200, friction: 30 },
    delay: 100,
  });
  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8 max-w-screen-xl mx-auto">
        <div className="grid col-span-2 gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          <AnimatedCard>
            <DashboardWorkspaceCard
              selectedWorkspace={selectedWorkspace}
              workspaces={workspaces}
              loadingProfile={loadingProfile}
            />
          </AnimatedCard>
        </div>
        <div className="grid col-span-4">
          <animated.div style={cardSpring}>
            <DashboardGuideCard />
          </animated.div>
        </div>
        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
          {portfolioValue && portfolioValue > 0 ? (
            <animated.div style={cardSpring}>
              <Card x-chunk="dashboard-01-chunk-0">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Portfolio
                  </CardTitle>
                  <DollarSign className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">
                    {portfolioValue && formatBalance(portfolioValue)}
                  </div>
                  {/*<p className="text-xs text-muted-foreground">
                              +20.1% from last month
                            </p>{" "} */}
                </CardContent>
              </Card>
            </animated.div>
          ) : (
            <></>
          )}
          {totalReports && totalReports > 0 ? (
            <animated.div style={cardSpring}>
              <Card x-chunk="dashboard-01-chunk-1">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Workspace Reports
                  </CardTitle>
                  <AreaChart className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{totalReports}</div>
                  {/*<p className="text-xs text-muted-foreground">
                             +180.1% from last month
                           </p>*/}
                </CardContent>
              </Card>
            </animated.div>
          ) : (
            <></>
          )}
          {totalReportEntries && totalReportEntries > 0 ? (
            <animated.div style={cardSpring}>
              <Card x-chunk="dashboard-01-chunk-2">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Report Entries
                  </CardTitle>
                  <ChartNoAxesCombined className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{totalReportEntries}</div>
                  {/*<p className="text-xs text-muted-foreground">
                           +19% from last month
                         </p> */}
                </CardContent>
              </Card>
            </animated.div>
          ) : (
            <></>
          )}

          {totalNumberOfTokens && totalNumberOfTokens > 0 ? (
            <animated.div style={cardSpring}>
              <Card x-chunk="dashboard-01-chunk-3">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Tokens Entries
                  </CardTitle>
                  <Activity className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{totalNumberOfTokens}</div>
                </CardContent>
              </Card>
            </animated.div>
          ) : (
            <></>
          )}
        </div>
        <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          {portfolioValue && portfolioValue > 0 ? (
            <animated.div style={cardSpring} className="xl:col-span-2">
              <AreaChartCard />
            </animated.div>
          ) : (
            <></>
          )}
          {totalNumberOfTokens && totalNumberOfTokens > 0 ? (
            <animated.div style={cardSpring}>
                <PieChartCard data={data?.tokenAllocation} />
            </animated.div>
          ) : (
            <></>
          )}
        </div>

        <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          {recentTransactions && recentTransactions.length > 0 ? (
            <animated.div style={cardSpring} className="xl:col-span-2">
              <Card x-chunk="dashboard-01-chunk-6">
                <CardHeader className="flex flex-row items-center">
                  <div className="grid gap-2">
                    <CardTitle>Transactions</CardTitle>
                    <CardDescription>Recent transactions.</CardDescription>
                  </div>
                  <Button asChild size="sm" className="ml-auto gap-1">
                    <a href="#">
                      View All
                      <ArrowUpRight className="h-4 w-4" />
                    </a>
                  </Button>
                </CardHeader>
                <CardContent className="max-h-72 overflow-y-auto">
                  <TransactionsTable transactions={recentTransactions} />
                </CardContent>
              </Card>
            </animated.div>
          ) : (
            <></>
          )}

          {isFeatureReady && recentTransactions && recentTransactions.length > 0 ? (
            <animated.div
              style={cardSpring}
              className="flex justify-center items-center"
            >
              <Card x-chunk="dashboard-01-chunk-7">
                <CardHeader>
                  <CardTitle>Recent Source Entries</CardTitle>
                </CardHeader>
                <CardContent className="grid gap-8"></CardContent>
              </Card>
            </animated.div>
          ) : (
            <></>
          )}
        </div>
      </main>
    </div>
  );
}
