import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // For redirection
import { cn, useWindowDimensions } from "@/lib/utils";
import { ModeToggle } from "@/components/theme/ModeToggle";
import { Menu } from "lucide-react";
import { UserGuideCard } from "../cards/UserGuideCard";
import { AvatarMenu } from "../user/AvatarMenu";
import { Sidebar } from "./SideBar";
import logo from "/logo-small.svg";
import { Button } from "../ui/button";
import useAuthStore from "@/stores/auth.store"; // Use your auth store

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const windowDimensions = useWindowDimensions();
  const [drawer, setDrawer] = useState(windowDimensions.width >= 768);
  const { user, loading } = useAuthStore(); 
  const navigate = useNavigate(); 

  useEffect(() => {
    setDrawer(windowDimensions.width >= 768 ? true : false);
  }, [windowDimensions]);

  useEffect(() => {
    if (!loading && !user) {
      navigate("/login"); // Redirect to login page
    }
  }, [loading, user, navigate]);

  const isSmallScreen = () => windowDimensions.width < 768;

  return (
    <div className="bg-gray-100 dark:bg-gray-900 h-screen">
      <div className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              {isSmallScreen() ? (
                <Button
                  onClick={() => setDrawer(!drawer)}
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg bg-gray-100 ring-2 ring-gray-200 dark:text-gray-400 dark:bg-gray-700 dark:ring-gray-600"
                >
                  <Menu />
                </Button>
              ) : null}

              <div className="flex ms-4 md:me-24 items-center space-x-3">
                <span className="self-center text-2xl font-semibold whitespace-nowrap text-gray-900 dark:text-white">
                  Ledgernalysis
                </span>
                <img src={logo} className="h-6" alt="Logo" />
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3">
                <ModeToggle />
              </div>
              <div className="flex items-center ms-3">
                <AvatarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>

      {drawer ? (
        <div className="fixed top-0 left-0 z-40 w-64 h-screen pt-12 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
            <Sidebar className={""} />
          </div>
        </div>
      ) : null}

      <div
        className={cn(
          isSmallScreen()
            ? "p-1 bg-gray-100 dark:bg-gray-900"
            : "p-1 sm:ml-60 bg-gray-100 dark:bg-gray-900"
        )}
      >
        <div className="p-4 mt-14 h-fit text-gray-900 dark:text-gray-100">
          {children}
        </div>
      </div>
      <UserGuideCard />
    </div>
  );
};