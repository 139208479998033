import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import MultiStepFormLayout from "@/components/layout/MultiStepLayout";
import { DisplayTitle } from "@/components/stepper/DisplayTitle";
import { MultiForm } from "@/components/stepper/MultiForm";
import { SOURCE_PAGE_DESCRIPTION, SOURCE_PAGE_HEADING } from "@/config/general";

export default function MultiStepForm() {
  return (

      <DashboardMainCard
        header={SOURCE_PAGE_HEADING}
        description={SOURCE_PAGE_DESCRIPTION}
      >

    <MultiStepFormLayout>
    <div className="mx-6 flex h-[450px] flex-col gap-2 rounded-md bg-white p-6 dark:bg-gray-800 px-4 shadow-md lg:col-span-2 lg:col-start-2 lg:row-span-3 lg:row-start-2 lg:mx-0 lg:-mt-0 lg:h-[540px] lg:px-16 lg:py-0 lg:shadow-none">
      <DisplayTitle />
      <MultiForm />
    </div>
  </MultiStepFormLayout>
      </DashboardMainCard>
  );
}
