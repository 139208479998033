import {
  safelyAccessFirstAssetArray,
  safelyAccessFlatArray,
} from "@/lib/utils";
import {
  ApiTransactionsPayload,
  AssetTransaction,
  ChartDataItem,
  DownloadResponse,
  Report,
  ReportGroup,
  TransactionData,
} from "@/types/report.type";
import { create } from "zustand";
import {
  deleteReportGroup,
  downloadReportGroup,
  downloadTx,
  getAllReportGroups,
  getGraphData,
  getReportsPage,
  getTransactions,
} from "../services/reportService";
import useWorkspaceStore from "./workspaces.store";

type ReportsState = {
  loading: boolean;
  reportGroups: ReportGroup[];
  reports: Report[];
  report: Report | null;
  selectedReportGroup: string;
  selectedReportGroupData: ReportGroup | null;
  fetchAllReportGroups: (
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<ReportGroup>>;
  fetchAllReportEntriesForSelectedGroup: (
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<Report>>;
  fetchReportEntriesForSelectedGroup: (
    reportGroupId: string,
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<Report>>;
  setSelectedReportGroup: (reportGroupId: string) => void;
  setSelectedReportGroupData: (reportGroup: ReportGroup) => void;
  setSelectedReportData: (report: Report) => void;
  deleteReportGroup: (reportGroupId: string) => Promise<void>;
  getTransactions: (
    reportId: string,
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<TransactionData>>;
  getGraphData: (reportId: string) => Promise<ChartDataItem[]>;
  downloadTx: (reportId: string) => Promise<DownloadResponse>;
  downloadReport: () => Promise<string>;
};

const useReportsStore = create<ReportsState>((set, get) => ({
  loading: false,
  reportGroups: [],
  reports: [],
  report: null,
  selectedReportGroup: "",
  selectedReportGroupData: null,

  fetchAllReportGroups: async (limit: number = 10, page: number = 1) => {
    set({ loading: true });
    const workspace = useWorkspaceStore.getState().getSelectedWorkspace();
    const workspaceId = workspace?.workspaceId || "";

    if (!workspaceId) {
      set({ loading: false });
      return Promise.reject(new Error("Workspace ID is missing"));
    }

    try {
      const response = await getAllReportGroups(workspaceId, limit, page);
      set({
        reportGroups: response.data.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.data.pages || 0,
        max: response.data.data.max || 0,
        data: response.data.data.data,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching report groups:", error);
      return Promise.reject(error);
    }
  },

  fetchReportEntriesForSelectedGroup: async (
    reportGroupId: string,
    limit: number = 10,
    page: number = 1
  ) => {
    set({ loading: true });

    if (!reportGroupId) {
      set({ loading: false });
      return Promise.reject(new Error("Report Group ID is missing"));
    }

    try {
      const response = await getReportsPage(reportGroupId, limit, page);
      set({
        reports: response.data.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.data.pages || 0,
        max: response.data.data.max || 0,
        data: response.data.data.data,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching entries:", error);
      return Promise.reject(error);
    }
  },

  fetchAllReportEntriesForSelectedGroup: async (
    limit: number = 10,
    page: number = 1
  ) => {
    set({ loading: true });
    const selectedReportGroup = get().selectedReportGroup;

    if (!selectedReportGroup) {
      set({ loading: false });
      return Promise.reject(new Error("Selected Report Group is missing"));
    }

    try {
      const response = await getReportsPage(selectedReportGroup, limit, page);
      set({
        reports: response.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.data[0]?.pages || 0,
        max: response.data.data[0]?.max || 0,
        data: response.data.data[0],
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching report entries:", error);
      return Promise.reject(error);
    }
  },

  setSelectedReportGroup: (reportGroupId: string) => {
    set({
      selectedReportGroup: reportGroupId,
    });
    get().fetchAllReportEntriesForSelectedGroup(10, 1);
  },

  setSelectedReportGroupData: (reportGroup: ReportGroup) => {
    set({
      selectedReportGroupData: reportGroup,
    });
  },
  setSelectedReportData: (report: Report) => {
    set({
      report: report,
    });
  },

  deleteReportGroup: async (reportGroupId: string) => {
    set({ loading: true });
    try {
      await deleteReportGroup(reportGroupId);
      set({ loading: false });
      get().fetchAllReportGroups(10, 1);
    } catch (error) {
      set({ loading: false });
      console.error("Error deleting report group:", error);
    }
  },

  getTransactions: async (reportId: string, limit: number, page: number) => {
    set({ loading: true });

    try {
      const response = await getTransactions(reportId, limit, page);
      const flatTransactions = safelyAccessFlatArray<TransactionData>(
        response.data.data
      );
      set({ loading: false });
      return {
        page,
        limit,
        pages: response.data.data[0]?.pages || 0,
        max: response.data.data[0]?.max || 0,
        data: flatTransactions.sort((a, b) => b.blockheight - a.blockheight),
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching transactions:", error);
      throw error;
    }
  },

  getGraphData: async (reportId: string) => {
    set({ loading: true });

    try {
      const response = await getGraphData(reportId);

      // @ts-expect-error: Argument of type 'AxiosResponse<any, any>' is not assignable to parameter of type 'AssetApiResponse<AssetTransaction>'.
      const assetTransactions = safelyAccessFirstAssetArray<AssetTransaction>(response);

      set({ loading: false });
      return assetTransactions;
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching graph data:", error);
      throw error;
    }
  },

  downloadTx: async (reportId: string) => {
    try {
      const response = await downloadTx(reportId);
      return response.data;
    } catch (error) {
      console.error("Error downloading transaction report:", error);
      throw error;
    }
  },

  downloadReport: async () => {
    const reportGroupId = get().selectedReportGroup;

    try {
      const response = await downloadReportGroup(reportGroupId);
      return response.data.downloadLink;
    } catch (error) {
      console.error("Error downloading report group:", error);
      throw error;
    }
  },
}));

export default useReportsStore;
