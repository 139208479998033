import { Source, SourceGroup } from "@/types/sources.type";
import {
  downloadTemplate,
  getAllSourceGroups,
  getSourcesPage,
  uploadSource,
} from "../services/sourceService";

import { networksResponse } from "@/config/networks";
import { getNetworks } from "@/services/generalService";
import { ApiTransactionsPayload } from "@/types/report.type";
import { create } from "zustand";
import useWorkspaceStore from "./workspaces.store";

type SourcesState = {
  loading: boolean;
  sourceGroups: SourceGroup[];
  sources: Source[];
  clearSourceEntries: () => void;
  networks: any;
  setNetworks: (networks: any[]) => void;
  selectedSourceGroupId: string;
  selectedSourceGroup: SourceGroup | null;
  source: Source | null;
  setSelectedSourceGroup: (sourceGroupId: string) => void;
  setSelectedSourceGroupData: (sourceGroup: SourceGroup) => void;
  setSelectedSource: (sourceGroup: Source) => void;
  fetchAllSourceGroups: (
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<SourceGroup>>;
  fetchSourceEntriesForSelectedGroup: (
    sourceGroupId: string,
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<Source>>;
  uploadSource: (file: string, sourceGroupId: string) => Promise<void>;
  downloadTemplate: (type: string) => Promise<string>;
};

const useSourcesStore = create<SourcesState>((set, get) => ({
  loading: false,
  sourceGroups: [],
  sources: [],
  source: null,
  clearSourceEntries: () => set({ sources: [] }),
  networks: [],
  setNetworks: (networks) => set({ networks }),

  selectedSourceGroupId: "",
  selectedSourceGroup: null,

  fetchAllSourceGroups: async (
    limit: number = 100,
    page: number = 1
  ): Promise<ApiTransactionsPayload<SourceGroup>> => {
    const defaultPayload: ApiTransactionsPayload<SourceGroup> = {
      page,
      limit,
      pages: 0,
      max: 0,
      data: [],
    };
    set({ loading: true });
    const workspace = useWorkspaceStore.getState().getSelectedWorkspace();
    const workspaceId = workspace?.workspaceId || "";

    if (!workspaceId) {
      set({ loading: false });
      return defaultPayload;
    }

    try {
      const response = await getAllSourceGroups(workspaceId, limit, page);
      set({
        sourceGroups: response.data.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.pages || 0,
        max: response.data.max || 0,
        data: response.data.data.data,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching source groups:", error);
      return defaultPayload;
    }
  },

  setSelectedSourceGroup: (sourceGroupId) => {
    set({
      selectedSourceGroupId: sourceGroupId,
    });
    get().fetchSourceEntriesForSelectedGroup(sourceGroupId, 10, 1);
  },

  setSelectedSourceGroupData: (sourceGroup) => {
    set({
      selectedSourceGroup: sourceGroup,
      selectedSourceGroupId: sourceGroup.sourceGroupId,
    });
  },
  setSelectedSource: (source: Source) => {
    set({
      source: source,
    });
  },
  uploadSource: async (file, sourceGroupId) => {
    console.log("Uploading source:", file, sourceGroupId);
    await uploadSource(file, sourceGroupId);
    await get().fetchAllSourceGroups(10, 1);
    get().setSelectedSourceGroup(sourceGroupId);
    await get().fetchSourceEntriesForSelectedGroup(sourceGroupId, 10, 1);
  },

  fetchSourceEntriesForSelectedGroup: async (
    sourceGroupId: string,
    limit: number = 100,
    page: number = 1
  ): Promise<ApiTransactionsPayload<Source>> => {
    set({ loading: true });

    const defaultPayload: ApiTransactionsPayload<Source> = {
      page,
      limit,
      pages: 0,
      max: 0,
      data: [],
    };

    try {
      const response = await getSourcesPage(sourceGroupId, limit, page);
      set({
        sources: response.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.pages || 0,
        max: response.data.max || 0,
        data: response.data.data.data,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching entries:", error);
      return defaultPayload; // Return default payload on error
    }
  },

  downloadTemplate: async (type) => {
    const response = await downloadTemplate(type);
    return response.data;
  },
}));

const fetchNetworks = async () => {
  try {
    const response = await getNetworks();
    console.log("Networks", response);
    useSourcesStore.getState().setNetworks(response.data.data);
  } catch (error) {
    console.error("Error fetching networks:", error);
    // @ts-expect-error: Argument of type not ignable to parameter of type 'any'.
    useSourcesStore.getState().setNetworks(networksResponse?.data);
  }
};

fetchNetworks();

export default useSourcesStore;
