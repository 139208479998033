import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { format } from "date-fns";
import NetworkAsset from "@/components/misc/Asset";
import { lookupAsset } from "@/lib/utils";
import AddressCell from "../misc/AddressCell";

interface Transaction {
  reportId: string;
  asset: string;
  after: string;
  price: string;
  blocktime: number;
}

interface TransactionsTableProps {
  transactions: Transaction[];
}

function TransactionsTable({ transactions }: TransactionsTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Asset</TableHead>
          <TableHead className="hidden xl:table-cell">Date</TableHead>
          <TableHead className="text-right">Amount</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.map((tx, index) => (
          <TableRow key={index}>
            <TableCell>
              {/* Use flexbox to align the asset and NetworkAsset in a row */}
              <div className="flex items-center space-x-2">
                <NetworkAsset asset={lookupAsset(tx.asset)} />
                <span className="font-semibold text-sm mb-3">{tx.asset}</span>
              </div>
              <div className="text-xs text-muted-foreground">
                <AddressCell fullAddress={tx.reportId} />
              </div>
            </TableCell>
            <TableCell className="hidden xl:table-cell">
              {format(new Date(tx.blocktime), "yyyy-MM-dd HH:MM")}
            </TableCell>
            <TableCell className="text-right">
              {parseFloat(tx.after).toFixed(8)} {tx.asset}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TransactionsTable;