"use client";

import { TrendingUp } from "lucide-react";
import * as React from "react";
import { Label, Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { TokenAllocation } from "@/types/dashboard.type";
import { formatBalance } from "@/lib/utils";

// Type Guard to ensure we are dealing with valid TokenData
function isTokenData(
  value: any
): value is { totalAfter: number; totalValue: number } {
  return value && typeof value.totalAfter === "number";
}

interface PieChartCardProps {
  data: TokenAllocation | undefined | null;
}

const chartConfig = {
  visitors: {
    label: "All Assets",
  },
  default: {
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

// Define default token colors
const tokenColors: { [key: string]: string } = {
  eth: "#627EEA", // Default color for ETH
  ltc: "#345D9D", // Default color for LTC
  btc: "#F7931A", // Default color for BTC
  bnb: "#F3BA2F", // Default color for BNB
  default: "#A0AEC0", // Fallback color for unknown tokens
};

export function PieChartCard({ data }: PieChartCardProps) {
  const chartData = React.useMemo(() => {
    if (!data) return [];

    const totalSum = Object.keys(data).reduce((acc, token) => {
      const tokenData = data[token as keyof TokenAllocation];
      return acc + (isTokenData(tokenData) ? tokenData.totalAfter : 0);
    }, 0);

    return Object.keys(data)
      .map((token) => {
        const tokenData = data[token as keyof TokenAllocation];
        return isTokenData(tokenData)
          ? {
              name: token,
              value: (tokenData.totalAfter / totalSum) * 100, // Convert to percentage
              fill: tokenColors[token.toLowerCase()] || tokenColors.default, // Assign color
            }
          : null;
      })
      .filter(Boolean); // Remove null entries
  }, [data]);

  const totalAssets = React.useMemo(() => {
    return Object.keys(data || {}).reduce((acc, token) => {
      const tokenData = data?.[token as keyof TokenAllocation];
      return acc + (isTokenData(tokenData) ? tokenData.totalAfter : 0);
    }, 0);
  }, [data]);

  return (
    <Card className="flex flex-col" x-chunk="dashboard-01-chunk-5">
      <CardHeader className="items-center pb-0">
        <CardTitle>Token Allocation</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={chartData}
              dataKey="value" // Using relative values (percentage)
              nameKey="name"
              innerRadius={60}
              outerRadius={80} // Adjust as needed
              strokeWidth={5}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {formatBalance(totalAssets)}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Assets
                        </tspan>
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <div className="flex items-center gap-2 font-medium leading-none">
          <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground"></div>
      </CardFooter>
    </Card>
  );
}
