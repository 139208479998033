import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { GROUP_TYPES } from "@/config/general";
import { createReportWithSourceGroups } from "@/services/reportService";
import useAuthStore from "@/stores/auth.store";
import useReportsStore from "@/stores/reports.store";
import useSourcesStore from "@/stores/sources.store";
import { RunReportSchema, RunReportType } from "@/zod/report.zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { DatePicker } from "../ui/date-picker";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useToast } from "../ui/use-toast";
import { useApiWebSocketCtx } from "@/context/context";

interface RunReportFormProps {
  sourceGroupIds?: string[];
  sourceType?: string;
}

export const RunReportForm: FC<RunReportFormProps> = ({
  sourceGroupIds = [],
  sourceType = "BALA",
}) => {
  const email = useAuthStore((state) => state.user?.email);
  const { socket } = useApiWebSocketCtx();

  const { toast } = useToast();
  const navigate = useNavigate();

  const maxDate = new Date(Date.now() - 60 * 60 * 1000);
  const runReportForm = useForm<RunReportType>({
    resolver: zodResolver(RunReportSchema),
    defaultValues: {
      type: sourceType,
      sources: sourceGroupIds,
      date: maxDate,
    },
  });
  const filteredSourceGroups = useSourcesStore((state) =>
    state.sourceGroups.filter(
      (sourceGroup) => sourceGroup.groupType === runReportForm.getValues().type
    )
  );

  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );

  const fetchAllReportGroups = useReportsStore(
    (state) => state.fetchAllReportGroups
  );

  const selectedSourceNames = filteredSourceGroups
    .filter((sourceGroup) =>
      runReportForm.watch("sources").includes(sourceGroup.sourceGroupId)
    )
    .map((sourceGroup) => sourceGroup.sourceName);

  const onSubmit = async (data: RunReportType) => {
    if (!email) return;
    const payload = {
      email,
      type: data.type,
      sources: data.sources,
      ...(["BALA", "EXCH"].includes(data.type) && {
        date: data.date.toISOString().split(".")[0] + "Z",
        datetime: [new Date(data.date).getTime()],
      }),
    };
    console.log(" Payload", payload);

    // TODO: FIX THIS
    const response = await createReportWithSourceGroups(
      payload.sources,
      payload.datetime
    );

    const reportId = response.data.reportGroupId;

    if (!socket || !reportId) {
      return;
    }
    socket.emit("register", { type: "reportgrouprun", reference: reportId });

    toast({
      title: "Report is being generated",
      description: "Please wait while we generate your report",
    });

    // console.log('Response',reportId, response);
    await fetchAllReportGroups(100, 1);

    setSelectedReportGroup(reportId);

    navigate(`/reports/${reportId}`);
  };

  const handleSourceCheck = (sourceGroupId: string) => {
    const sources = runReportForm.getValues().sources;
    if (sources.includes(sourceGroupId)) {
      runReportForm.setValue(
        "sources",
        sources.filter((source) => source !== sourceGroupId)
      );
    } else {
      runReportForm.setValue("sources", [...sources, sourceGroupId]);
    }
  };

  return (
    <Form {...runReportForm}>
      <form
        onSubmit={runReportForm.handleSubmit(onSubmit)}
        className="space-y-8"
      >
        <FormField
          control={runReportForm.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {GROUP_TYPES.map((type, i) => (
                    <SelectItem value={type.value} key={i}>
                      {type.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={runReportForm.control}
          name="sources"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Sources</FormLabel>
              <DropdownMenu>
                <FormControl>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="w-full">
                      {selectedSourceNames?.length > 0
                        ? selectedSourceNames.join(", ")
                        : "Select Sources"}
                    </Button>
                  </DropdownMenuTrigger>
                </FormControl>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>Sources</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  {filteredSourceGroups.map((sourceGroup) => (
                    <DropdownMenuCheckboxItem
                      checked={field.value.includes(sourceGroup.sourceGroupId)}
                      onCheckedChange={() =>
                        handleSourceCheck(sourceGroup.sourceGroupId)
                      }
                      key={sourceGroup.sourceGroupId}
                    >
                      {sourceGroup.sourceName}
                    </DropdownMenuCheckboxItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
              <FormMessage />
            </FormItem>
          )}
        />
        {["BALA", "EXCH"].includes(runReportForm.watch("type")) && (
          <FormField
            control={runReportForm.control}
            name="date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Target Date</FormLabel>
                <DatePicker
                  date={field.value}
                  setDate={field.onChange}
                  maxDate={maxDate}
                  includeTime
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <Button type="submit" className="w-full">
          Run Report
        </Button>
      </form>
    </Form>
  );
};
